import classNames from 'classnames';
import { useMemo } from 'react';

import { useSelector, useDispatch } from 'src/store';
import { selectCompany } from 'src/store/slices/companySlice';
import {
  selectedCompanyIdSelector,
  allCompanyOptionsSelector,
  isLoadingCompanySelector,
} from 'src/store/selectors/companySelector';

import { dropdownFilterOption } from '@itm/shared-frontend/lib/utils';
import Select, { SingleValue } from '@itm/shared-frontend/lib/components/react-select';

import { SelectOption } from 'src/types';

function CompanyFilter() {
  const dispatch = useDispatch();
  const selectedCompanyId = useSelector(selectedCompanyIdSelector);
  const companyOptions = useSelector(allCompanyOptionsSelector);
  const isLoadingCompanies = useSelector(isLoadingCompanySelector);

  const isFilterAvailable = true;

  const selectedCompanyOption = useMemo(
    () => companyOptions.find((option) => option.value === selectedCompanyId) || null,
    [companyOptions, selectedCompanyId],
  );

  const companyChangeHandler = (newOption: SingleValue<SelectOption>) => {
    if (newOption) {
      dispatch(selectCompany(newOption.value));
    }
  };

  return (
    <div className="is-flex is-justify-content-center pt-5">
      <div className="control is-fullwidth">
        <Select
          className={classNames('react-select', { 'is-loading': isLoadingCompanies })}
          classNamePrefix="react-select"
          value={selectedCompanyOption}
          options={companyOptions}
          aria-label="Company name"
          placeholder={companyOptions.length || isLoadingCompanies ? 'Company name' : 'No companies'}
          onChange={companyChangeHandler}
          isDisabled={!isFilterAvailable || isLoadingCompanies || !companyOptions.length}
          isClearable={false}
          isSearchable={true}
          closeMenuOnSelect={true}
          filterOption={dropdownFilterOption}
        />
      </div>
    </div>
  );
}

export default CompanyFilter;
