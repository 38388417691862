import { Link } from 'react-router-dom';
import { RoutePath } from 'src/router';

import { clientPortalDashboardUrl } from '@itm/shared-frontend/lib/utils';

export default function PermissionDenied() {
  return (
    <div className="pt-6">
      <div className="container px-2">
        <div className="content card px-5 py-5">
          <h1 className="is-size-2">Permission Denied</h1>
          <p>
            You may visit <Link to={RoutePath.root}>the Main Page</Link>
          </p>
          <p>
            Or visit Client Portal Dashboard <a href={clientPortalDashboardUrl}>{clientPortalDashboardUrl}</a> to see
            all available modules
          </p>
        </div>
      </div>
    </div>
  );
}
