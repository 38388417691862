import { Suspense, useState, useLayoutEffect, lazy } from 'react';
import { Router, Routes, Route, Navigate } from 'react-router-dom';
import { Provider } from 'react-redux';
import { RoutePath, GuardedRoute, history } from 'src/router';

import store, { useSelector } from 'src/store';
import { isLoggedInSelector } from 'src/store/selectors/authSelector';

import { PageLoading } from '@itm/shared-frontend/lib/components';

import Layout from 'src/components/layout/Layout';
import AuthProvider from 'src/hocs/AuthProvider';
import SyncFiltersWithURL from 'src/hocs/SyncFiltersWithURL';
import BreadcrumbsTrail from 'src/components/BreadcrumbsTrail';

import LoginRedirectPage from 'src/pages/loginRedirect';
import PermissionDeniedPage from 'src/pages/permissionDenied';

const DataManagementRoutes = lazy(() => import('src/pages/dataManagement'));
const ProductInfoPage = lazy(() => import('src/pages/productInfo'));

import { Role } from 'src/types';

function RootRoutesFallback() {
  return (
    <div className="px-3">
      <div className="container">
        <PageLoading />
      </div>
    </div>
  );
}

function RootRoutes() {
  const isLoggedIn = useSelector(isLoggedInSelector);
  const redirectRoute = isLoggedIn ? RoutePath.dataManagementRoot : RoutePath.loginRedirect;

  return (
    <Layout>
      <BreadcrumbsTrail />

      <Suspense fallback={<RootRoutesFallback />}>
        <Routes>
          <Route index element={<Navigate to={redirectRoute} replace={true} />} />

          <Route path={RoutePath.loginRedirect} element={<LoginRedirectPage />} />
          <Route path={RoutePath.permissionDenied} element={<PermissionDeniedPage />} />

          <Route
            path={`${RoutePath.dataManagementRoot}/*`}
            element={
              <GuardedRoute
                meta={{
                  auth: true,
                  role: { allowList: [Role.SuperAdmin, Role.Support, Role.Analyst, Role.ClientAdmin, Role.Manager] },
                }}
              >
                <DataManagementRoutes />
              </GuardedRoute>
            }
          />

          <Route
            path={RoutePath.productInfo}
            element={
              <GuardedRoute
                meta={{
                  auth: true,
                  role: { allowList: [Role.SuperAdmin, Role.Support] },
                }}
              >
                <ProductInfoPage />
              </GuardedRoute>
            }
          />

          <Route path="*" element={<Navigate to={RoutePath.root} />} />
        </Routes>
      </Suspense>
    </Layout>
  );
}

function RootRouter() {
  const [state, setState] = useState({
    action: history.action,
    location: history.location,
  });
  useLayoutEffect(() => history.listen(setState), []);
  return (
    <Router location={state.location} navigationType={state.action} navigator={history}>
      <Provider store={store}>
        <AuthProvider>
          <SyncFiltersWithURL>
            <RootRoutes />
          </SyncFiltersWithURL>
        </AuthProvider>
      </Provider>
    </Router>
  );
}

export default RootRouter;
