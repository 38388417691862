export { history } from './history';

export const RoutePath = {
  root: '/',
  loginRedirect: '/login',
  permissionDenied: '/permission-denied',

  // Data management
  dataManagementRoot: '/data-management',
  dataManagementUploadHistoryRoot: '/data-management/upload-history',
  dataManagementUploadHistoryList: '/data-management/upload-history/list',
  dataManagementUploadHistoryFileViewRoot: '/data-management/upload-history/file/:fileId/view',
  dataManagementUploadHistoryFileViewUploadSummary: '/data-management/upload-history/file/:fileId/view/upload-summary',

  // Product Information
  productInfo: '/product-info',
};

export { GuardedRoute } from './GuardedRoute';
