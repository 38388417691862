import { GlobalRole } from '@itm/shared-frontend/lib/types';

export { ResultFailType } from '@itm/shared-frontend';
export type {
  GlobalRole,
  SelectOption,
  ServerError,
  ServerFormErrors,
  ExternalFilters,
  TanstackTableChangeParams,
} from '@itm/shared-frontend/lib/types';

export enum TargetPlatform {
  AWS = 'AWS',
  AZURE = 'AZURE',
}

export enum Role {
  SuperAdmin = 'SuperAdmin',
  Support = 'Support',
  Analyst = 'oPen_Analyst',
  ClientAdmin = 'oPen_ClientAdmin',
  Manager = 'oPen_Manager',
}

export enum LocalStorageKeys {
  DataManagementUploadHistoryTable = 'DATA_MANAGEMENT_UPLOAD_HISTORY_TABLE',
}

export enum DataUploadStatus {
  RawDataReceived = 'RawDataReceived',
  InProgress = 'InProgress',
  Completed = 'Completed',
  PartiallyCompleted = 'PartiallyCompleted',
  Failed = 'Failed',
}

export type UserPermissionResponse = {
  userId: string;
  companyId: string;
  companyName: string;
  schemeId: string;
  schemeName: string;
  productId: string;
  productName: string;
  productRoleId: string;
  productRoleName: GlobalRole;
  userProductRoleId: string;
  assignmentDate: string;
};

export type CompanyResponse = {
  id: string;
  name: string;
};

export type ProductInfoResponse = {
  id: string;
  description: string | null;
  version: string | null;
};

export type SchemeResponse = {
  id: string;
  name: string | null;
  companyId: string;
};

export type DataUploadSearchResponsePagedResponse = {
  totalCount: number;
  items: DataUploadSearchResponse[];
};

export type DataUploadSearchResponse = {
  id: string;
  name: string | null;
  uploadType: string;
  dataUploadType: string;
  format: string;
  status: DataUploadStatus;
  uploadedDate: string;
  uploadedBy: string;
};

export type DataUploadResponse = {
  id: string;
  companyId: string;
  name: string | null;
  uploadType: string;
  dataUploadType: string;
  format: string;
  status: DataUploadStatus;
  uploadedDate: string;
  uploadedBy: string;
  totalRecords: number;
  totalErrors: number;
  totalProcessed: number;
  errorMessage: string | null;
  DataUploadSchemes: SchemeResponse[];
};

export type DataUploadErrorSearchPagedRResponse = {
  totalCount: number;
  items: DataUploadErrorSearchResponse[];
};

export type DataUploadErrorSearchResponse = {
  dataUploadId: string;
  message: string | null;
  employerId: string;
  schemeId: string;
  employeeReference: string;
  memberReference: string;
};
