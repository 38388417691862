import { string, addMethod, StringSchema, DateValidationMessage } from 'yup';
import { getFormattedDate } from '@itm/shared-frontend/lib/components';
import { getNextDayISO, getPrevDayISO } from '@itm/shared-frontend/lib/utils';

addMethod<StringSchema>(string, 'emptyToUndefined', function () {
  return this.transform((value) => (value && value.length ? value : undefined));
});

addMethod<StringSchema>(string, 'dateFrom', function (dateToKey: string = 'dateTo', message?: DateValidationMessage) {
  return this.when(dateToKey, ([dateTo]: (string | undefined)[], scheme) =>
    dateTo
      ? scheme.test(
        'dateFromTest',
        (params) =>
          message
            ? typeof message === 'string'
              ? message
              : message(params, dateTo)
            : `${params.label} field must be earlier than ${getFormattedDate(getNextDayISO(dateTo))}`,
        (dateFrom) => (dateFrom ? new Date(dateFrom) < new Date(getNextDayISO(dateTo)) : true),
      )
      : scheme,
  );
});

addMethod<StringSchema>(string, 'dateTo', function (dateFromKey: string = 'dateFrom', message?: DateValidationMessage) {
  return this.when(dateFromKey, ([dateFrom]: (string | undefined)[], scheme) =>
    dateFrom
      ? scheme.test(
        'dateToTest',
        (params) =>
          message
            ? typeof message === 'string'
              ? message
              : message(params, dateFrom)
            : `${params.label} field must be later than ${getFormattedDate(getPrevDayISO(dateFrom))}`,
        (dateTo) => (dateTo ? new Date(getNextDayISO(dateTo)) > new Date(dateFrom) : true),
      )
      : scheme,
  );
});
